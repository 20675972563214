<template>
  <div>
    <div class="box mb15">
        <el-page-header @back="$router.go(-1)" :content="$route.query.title"></el-page-header>
    </div>
    <el-card class="mb15" shadow="hover">
      <el-row>
        <el-input
          :placeholder="$t('search')"
          clearable
          v-model="filter.search"
          @keyup.enter.native="get_list"
        ></el-input>
      </el-row>
      <el-row class="mt20" type="flex" :gutter="20" align="middle">
          <span class="text">{{$t('examination.type')}}:</span>
          <el-radio-group size="medium" v-model="filter.type">
               <el-radio label>{{$t('all')}}</el-radio>
              <el-radio :label="1">{{type(1)}}</el-radio>
              <el-radio :label="2">{{type(2)}}</el-radio>
              <el-radio :label="3">{{type(3)}}</el-radio>
              <el-radio :label="4">{{type(4)}}</el-radio>
          </el-radio-group>
      </el-row>
      <el-row class="mt20" type="flex" :gutter="20" align="middle">
          <span class="text">{{$t('examination.level')}}:</span>
           <el-radio-group size="medium" v-model="filter.level">
               <el-radio label>{{$t('all')}}</el-radio>
              <el-radio :label="1">{{level(1)}}</el-radio>
              <el-radio :label="2">{{level(2)}}</el-radio>
              <el-radio :label="3">{{level(3)}}</el-radio>
          </el-radio-group>
      </el-row>
      <el-row class="mt20" type="flex" align="middle">
        <el-button @click="get_list" type="primary" class="search">{{
          $t("search_zh")
        }}</el-button>
        <el-button @click="reset" type="info">{{
          $t("btnTip.reset")
        }}</el-button>
      </el-row>
    </el-card>
    <el-card shadow="hover">
      <div class="flexBC mb20">
        <el-button type="primary" @click="handleClick('add')">{{
          $t("btnTip.add")
        }}</el-button>
        <el-button type="primary" @click="outExe">{{ $t("outExe") }}</el-button>
      </div>
      <my-table
        :columns="columns"
        :data="list_data.list"
        :showIndex="false"
        :showSelection="false"
        :cellStyle="{ padding: '6px 0' }"
        :headerCellStyle="{
          background: 'rgba(51, 55, 68, 1)',
          color: '#fff',
        }"
        @emitSelection="allSelect"
        @sortChange="sort_change"
      >
      <template v-slot:type="slotProps">
          <el-tag :type="typeTag(slotProps.callback.row.type)">
            {{ type(slotProps.callback.row.type) }}
          </el-tag>
        </template>
        <template v-slot:level="slotProps">
          <el-tag :type="levelTag(slotProps.callback.row.level)">
            {{ level(slotProps.callback.row.level) }}
          </el-tag>
        </template>
        <template v-slot:result="slotProps">
          {{ (slotProps.callback.row.result_true+slotProps.callback.row.result_false) > 0 ? (slotProps.callback.row.result_true*100 / (slotProps.callback.row.result_true+slotProps.callback.row.result_false)).toFixed(2) : 0 }}%
        </template>
        <template v-slot:status="slotProps">
          <el-tag :type="statusTag(slotProps.callback.row.status)">
            {{ status(slotProps.callback.row.status) }}
          </el-tag>
        </template>
        <template v-slot:operation="slotProps">
         <el-tooltip
            class="item"
            effect="dark"
            :content="$t('btnTip').check"
            placement="top"
          >
            <el-button
              @click="handleClick('check', slotProps.callback.row)"
              icon="el-icon-view"
              type="success"
              size="mini"
            ></el-button>
          </el-tooltip>
          <el-tooltip
            class="item"
            effect="dark"
            :content="$t('btnTip').edit"
            placement="top"
          >
            <el-button
              @click="handleClick('edit', slotProps.callback.row)"
              icon="el-icon-edit-outline"
              :type="'primary'"
              size="mini"
            ></el-button>
          </el-tooltip>
          <el-tooltip
            class="item"
            effect="dark"
            :content="
              slotProps.callback.row.status === 0
                ? $t('examination.status1')
                : $t('examination.status0')
            "
            placement="top"
          >
            <el-button
              @click="handleClick('status', slotProps.callback.row)"
              :type="
                slotProps.callback.row.status === 0 ? 'success' : 'warning'
              "
              size="mini"
              >{{
                slotProps.callback.row.status === 0
                ? $t('examination.status1')
                : $t('examination.status0')
              }}</el-button
            >
          </el-tooltip>
          <el-tooltip
            class="item"
            effect="dark"
            :content="$t('btnTip').delete"
            placement="top"
          >
            <el-button
              @click="del(slotProps.callback.row.id)"
              type="danger"
              size="mini"
              >{{ $t("btnTip").delete }}</el-button
            >
          </el-tooltip>
        </template>
      </my-table>
      <page
        :total="list_data.total"
        :page_size.sync="pageSize"
        :page.sync="page"
      />
    </el-card>
  </div>
</template>
<script>
import { mixin_list } from "@/mixins";
import page from "@/components/page";
import myTable from "@/components/table";
import { examinationList, deleteExamination, editExamination } from "@/api/index";
export default {
  name: "examination",
  mixins: [mixin_list(examinationList)],
  components: {
    myTable,
    page,
  },
  data() {
    return {
      filter: {
        search: "",
        ordering:"",
        section:this.$route.params.sectionId,
        type:'',
        level:''
      },
      columns: [
        {
          label: "ID",
          sortable: true,
          prop: "id",
          width: 80,
        },
        {
          label: this.$t("examination.order"),
          sortable: true,
          prop: "order",
        },
        {
          label: this.$t("examination.type"),
          sortable: true,
          prop: "type",
          slot: "type",
        },
        {
          label: this.$t("examination.level"),
          sortable: true,
          prop: "level",
          slot: "level",
        },
        // {
        //   label: this.$t("examination.order"),
        //   sortable: true,
        //   prop: "order",
        // },
        {
          label: this.$t("examination.result"),
          slot: "result",
          prop: "result",
        },
        {
          label: this.$t("examination.status"),
          sortable: true,
          prop: "status",
          slot: "status",
        },
        {
          label: this.$t("operation"),
          prop: "",
          width: 350,
          slot: "operation",
        },
      ],
    };
  },
  methods: {
      type(type) {
      switch (type) {
        case 1:
          return this.$t("examination.type1");
        case 2:
          return this.$t("examination.type2");
        case 3:
          return this.$t("examination.type3");
        case 4:
          return this.$t("examination.type4");
      }
    },
    typeTag(typeTag){
        switch (typeTag) {
        case 1:
          return 'success';
        case 2:
          return 'warning';
        case 3:
          return 'success';
        case 4:
          return 'warning';
      }
    },
    level(level) {
      switch (level) {
        case 1:
          return this.$t("examination.level1");
        case 2:
          return this.$t("examination.level2");
        case 3:
          return this.$t("examination.level3");
      }
    },
    levelTag(levelTag){
        switch (levelTag) {
        case 1:
          return 'success';
        case 2:
          return 'warning';
          case 3:
          return 'danger';
      }
    },
    status(status) {
      switch (status) {
        case 0:
          return this.$t("examination.status0");
        case 1:
          return this.$t("examination.status1");
      }
    },
    statusTag(statusTag){
        switch (statusTag) {
        case 0:
          return 'warning';
        case 1:
          return 'success';
      }
    },
    async handleClick(type, data) {
      switch (type) {
        case "check":
          this.$router.push(`/category/subject/${this.$route.params.subjectId}/chapter/${this.$route.params.chapterId}/section/${this.$route.params.sectionId}/examination/detail?id=${data.id}`);
          break;
        case "add":
          this.$router.push(`/category/subject/${this.$route.params.subjectId}/chapter/${this.$route.params.chapterId}/section/${this.$route.params.sectionId}/examination/edit`);
          break;
        case "status":
          await editExamination({
            ...data,
            status: data.status === 0 ? 1 : 0,
          });
          this.get_list();
          break;
        case "edit":
          this.$router.push(`/category/subject/${this.$route.params.subjectId}/chapter/${this.$route.params.chapterId}/section/${this.$route.params.sectionId}/examination/edit?id=${data.id}`);
          break;
      }
    },
    async del(id) {
      this.$confirm(this.$t('sureDelete'), this.$t('tips'), {
        confirmButtonText: this.$t('btnTips').submit,
        cancelButtonText: this.$t('btnTips').cancel,
        type: 'warning'
      }).then(async () => {
        await deleteExamination(id)
        this.get_list()
      }).catch({
      })
    },
  },
};
</script>
<style lang="scss" scoped>
.text{
  width:100px;
  font-size: 16px;
}
/deep/ label{
  margin-bottom:0!important
}
</style>